$colors: (
  white: #fff ,
  dark: #08091b ,
  contrast: #121422 ,
  ambient-text: #9ba4b5 ,
  bg-gradient-dark: linear-gradient(180deg, #08091b 0%, #100d26 100%),
  bg-light: #f7f9fb,
  table-item-border-dark: rgba(107, 70, 255, 0.1),
  table-item-bg-dark: rgba(94, 56, 231, 0.05),
  table-item-checking-in: #f9fafc,
  table-item-checking-in-dark: rgba(94, 56, 231, 0.02),
  blinking-green: #8ed3a5,
  green: #37b05f,
  blue: #0bcaf9,
  error: #fe3e7f
);
