*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
}

p,
ul,
li,
h2 {
  margin: 0;
  padding: 0;
}

img[src=""],
img[src="null"],
img[src="undefined"] {
  width: 0;
  height: 0;
}

body {
  font-family: $fontFam;
}
