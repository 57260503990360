@import "./colors.scss";
@import "./mixins.scss";

$body-fs: 0.8333vmax;

$fontFam: 'Lato', sans-serif;

$fw-bold: 700;

$font-sizes: (
  body-desktop: 1em ,
  header-text: 1.625em,
  table-header: 1.375em,
  table-default: 1.625em,
  table-big: 2em,
  table-ambient-relative: 0.7692em,
  table-ambient: 1.25em,
);

$font-sizes-portrait: (
  header-text: 1.5em,
  table-header: 1.125em,
  table-default: 1.5em,
  table-big: 1.75em,
  table-ambient: 1.125em,
);
