@import "./vars.scss";
@import "./reset.scss";

html,
body,
#root,
.screensApp {
  height: 100%;
  background-color: map-get($colors, bg-light);
}

body {
  font-size: $body-fs;
}

.screensApp {
  display: flex;
  flex-direction: column;
}
