@import "~styles/vars.scss";

.appointments {
  &__row {
    padding: 0.2812em 0;
  }

  &__item {
    display: flex;
    padding: 1em 1.875em;
    border: 1px solid #dedee0;
    border-radius: 0.625em;
    background-color: #fff;
    transition: background-color 500ms ease;
    //flex-wrap: wrap;
    align-items: center;
  }

  &__item--checkingIn {
    background-color: map-get($colors, table-item-checking-in);
  }

  &__time {
    max-width: 16.875em;
    flex-basis: 16.875em;
  }

  &__timeScheduled {
    padding-bottom: 0.5625em;

    > p {
      font-size: map-get($font-sizes, table-default);
    }
  }

  &__customer {
    display: flex;
    max-width: 40.5em;
    flex-basis: 40.5em;
    //flex-grow: 1;
  }

  &__customerText {
    padding-left: 1.875em;
  }

  &__customerName {
    align-self: center;
    padding-bottom: 0.375em;
    font-size: map-get($font-sizes, table-big);
  }

  &__car {
    max-width: 5.625em;
    min-height: 100%;
    border-radius: 6px;
    background-color: map-get($colors, white);
    flex-basis: 5.625em;
  }

  &__carImage {
    width: 100%;
    height: auto; //100%;
    min-height: 3.75em;
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: contain;
  }

  &__carName {
    font-size: map-get($font-sizes, table-ambient);
  }

  &__advisor {
    display: flex;
    max-width: 26.25em;
    flex-basis: 26.25em;
    align-items: center;
  }

  &__advisorImage {
    width: 3.75em;
    height: 3.75em;
    margin-right: 1.5625em;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border-radius: 50%;

    @media (orientation: portrait) {
      margin-right: 0.9375em;
    }
  }

  &__advisorName {
    //padding-left: 1.5625em;
    font-size: map-get($font-sizes, table-default);

    @media (orientation: portrait) {
      //padding-left: 0.9375em;
      font-size: map-get($font-sizes-portrait, table-default);
    }
  }

  &__status {
    display: flex;
    max-width: 26.25em;
    flex-basis: 26.25em;
    align-items: center;
    justify-content: space-between;
  }

  &__statusText {
    font-size: map-get($font-sizes, table-default);

    @media (orientation: landscape) {
      //flex-basis: 14.735em;
    }

    @media (orientation: portrait) {
      padding-bottom: 0.625em;
    }
  }

  &__icons {
    display: flex;
    padding-inline-start: 0;
    list-style-type: none;
    justify-content: space-evenly;
  }

  &__icon {
    padding: 0 0.625em;
    opacity: 0;
    transition: opacity 500ms ease-out;

    &:last-of-type {
      padding-right: 0;
    }

    > svg {
      width: 2.5em;
      height: 2.5em;
    }
  }

  &__check {
    @extend .appointments__icon;

    svg > path {
      transition: fill 500ms ease-out;
    }
  }

  &__check--progressKiosk {
    opacity: 1;

    svg > path {
      fill: map-get($colors, blinking-green);
      animation: check-blinking-green 2000ms ease-in-out infinite;
    }
  }

  &__check--checkedIn {
    opacity: 1;

    svg > path {
      fill: map-get($colors, green);
    }
  }

  &__check--notArrived {
    opacity: 1;

    svg > path {
      fill: map-get($colors, blue);
    }
  }

  &__icon--show {
    opacity: 1;
  }
}

@media (orientation: portrait) {
  .appointments {
    padding: 2.5em 2.1875em;

    &__item {
      padding: 1.4375em 1.5625em;
      justify-content: space-between;
    }

    &__time {
      max-width: 8.75em;
      flex-basis: 8.75em;
    }

    &__timeScheduled {
      padding-bottom: 0.375em;

      > p {
        font-size: map-get($font-sizes-portrait, table-default);
      }
    }

    &__customer {
      max-width: 23.4375em;
      flex-basis: 23.4375em;
    }

    &__customerText {
      padding-left: 1.25em;
    }

    &__customerName {
      font-size: map-get($font-sizes-portrait, table-big);
    }

    &__car {
      max-width: 4.6875em;
      flex-basis: 4.6875em;
    }

    &__carName {
      font-size: map-get($font-sizes-portrait, table-ambient);
    }

    &__advisor {
      max-width: 14.6875em;
    }

    &__advisorImage {
      width: 3.125em;
      height: 3.125em;
    }

    &__advisorName {
      font-size: map-get($font-sizes-portrait, table-default);
    }

    &__status {
      max-width: 13.4375em;
      flex-basis: 13.4375em;
      flex-direction: column;
      align-items: flex-start;
    }

    &__statusText {
      font-size: map-get($font-sizes-portrait, table-default);
    }

    &__icons {
      min-width: 7.5em;
    }

    &__icon {
      &:first-of-type {
        padding-left: 0;
      }

      > svg {
        width: 1.875em;
        height: 1.875em;
      }
    }
  }
}

@keyframes check-blinking-green {
  0% {
    fill: map-get($colors, green);
  }

  50% {
    fill: map-get($colors, blinking-green);
  }

  100% {
    fill: map-get($colors, green);
  }
}
